<template>
  <v-container fluid fill-height>
    <v-row class="text-center">
      <!-- Carousel section for large screens -->
      <v-col lg="6" class="d-none d-lg-flex">
        <v-container fluid fill-height>
          <v-carousel height="90vh" show-arrows="hover" cycle hide-delimiter-background>
            <v-carousel-item v-for="(slide, i) in slides" :key="i">
              <v-img :src="slide"></v-img>
            </v-carousel-item>
          </v-carousel>
        </v-container>
      </v-col>

      <!-- Chat section -->
      <v-col lg="6" md="12" class="d-flex flex-column" fill-height>
        <v-card class="d-flex flex-column elevation-0 chat-card">
          <v-card-title>
            <v-icon size="x-large">mdi-robot</v-icon> &nbsp; Spiro Chatbot
          </v-card-title>
          <v-card-subtitle>
            Ask anything about Spiro
          </v-card-subtitle>
          <v-card-text class="chat-content" ref="chatContent">
            <div v-for="(item, index) in messages" :key="index" class="message-container">
              <v-card class="elevation-0">
                <v-card-text :class="{ 'user': item.type === 'user', 'chatbot': item.type === 'chatbot' }">
                  <v-icon size="x-large">
                    {{ item.type == 'user' ? 'mdi-account' : 'mdi-robot' }}
                  </v-icon>
                  &nbsp;
                  <div :class="{ 'user-text': item.type === 'user', 'chatbot-text': item.type === 'chatbot' }">
                    {{ item.text }}
                  </div>
                </v-card-text>
              </v-card>
            </div>

            <!-- Show typing indicator -->
            <div v-if="isThinking" class="typing-indicator">
              <v-progress-circular color="grey" :size="25" indeterminate></v-progress-circular> <span style="color: gray;">Thinking...</span>
            </div>
          </v-card-text>
          <v-card-actions class="chat-actions">
            <v-text-field placeholder="Ask about Spiro & Spiro products & services" single-line rows="1" auto-grow dense
              rounded hide-details variant="outlined" bg-color="grey-lighten-3" append-inner-icon="mdi-send"
              :on-click:append-inner="sendMessage" class="custom-textarea" v-model="userInput"
              @keydown.enter="sendMessage"></v-text-field>
          </v-card-actions>
          <div v-if="messages.length == 0">
            <v-chip color="blue" v-for="suggestion in suggestions" :key="suggestion" class="ma-2"
              @click="clickSuggestion(suggestion)">
              {{ suggestion }}
            </v-chip>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HelloWorld',

  data: () => ({
    slides: [
      'https://spironet.com/wp-content/uploads/2023/09/Spiro-Blog-IMG_-4-_-African-EV-Startups-copy-1.jpg',
      'https://spironet.com/wp-content/uploads/2023/04/560x560_capital.jpg',
      'https://spironet.com/wp-content/uploads/2023/04/Our_products-1.webp',
      'https://spironet.com/wp-content/uploads/2023/04/Swap_go.webp',
    ],
    userInput: '',
    messages: [],
    suggestions: [
      'About Spiro?',
      'Spiro bikes?',
      'Benefits of owning spiro bike?',
      'Swap Stations?',
      'Spiro after sales services?',
      'Sipro insurance policy?'
    ],
    responseData: '',
    isThinking: false,
  }),

  methods: {
    clickSuggestion(msg) {
      this.userInput = msg;
      this.sendMessage();
    },
    sendMessage() {
      if (this.userInput.trim() == '') {
        this.userInput = null
        return;
      }
      this.isThinking = true

      // Add user message to messages array
      this.messages.push({ type: 'user', text: this.userInput });

      // Simulate an API call and response
      this.getChatbotResponse();
    },

    async getChatbotResponse() {
      // Simulate an API call (Replace this with actual API request)
      try {
        const formData = new FormData();
        formData.append('question', this.userInput);
        this.userInput = null;

        const result = await axios.post('https://bodascorecard.spiroiothub.com/get_response', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.responseData = result.data;
        this.isThinking = false
      } catch (error) {
        this.error = error.message;
      }
      setTimeout(() => {

        // Add chatbot response to messages array
        this.messages.push({ type: 'chatbot', text: '' }); // Add empty response initially

        // Animate response word by word
        this.animateResponse(this.responseData);
      }, 1000); // Simulate network delay
    },

    animateResponse(response) {
      const words = response.split(' ');
      let index = 0;
      const messageIndex = this.messages.length - 1;

      const interval = setInterval(() => {
        if (index < words.length) {
          this.messages[messageIndex].text += (index === 0 ? '' : ' ') + words[index];
          index++;
        } else {
          clearInterval(interval);
        }
      }, 100); // Adjust the interval to control typing speed

      // Ensure scrolling to bottom after animation starts
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const chatContent = this.$refs.chatContent;
        if (chatContent) {
          chatContent.scrollTop = chatContent.scrollHeight;
        }
      });
    },
  },

  watch: {
    messages() {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    }
  }
}
</script>

<style scoped>
.chat-card {
  height: calc(100vh - 64px);
  /* Adjust height based on the viewport height */
  display: flex;
  flex-direction: column;
}

.chat-content {
  overflow-y: auto;
  flex: 1;
  /* Allow this section to grow and fill the space */
}

.custom-textarea {
  width: 100%;
  /* Ensure the textarea takes the full width */
}

.custom-textarea .v-input__append-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user {
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
}

.user-text {
  padding: 10px;
  background-color: rgba(128, 128, 128, 0.241);
  border-radius: 16px;
}

.chatbot-text {
  padding: 10px;
  border-radius: 16px;
  border: 1px solid grey;
}

.chatbot {
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  width: 80%;
}

.message-container {
  margin-bottom: 10px;
  /* Adjust spacing between messages if needed */
}

.chat-actions {
  position: sticky;
  bottom: 100;
  background: white;
  z-index: 1;
}
</style>
